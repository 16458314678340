@value red, blue, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .loginFormContainer {
    display: flex;
    gap: md;
    flex-direction: column;
    align-items: stretch;
  }

  .loginWithPassword {
    margin-top: sm;
    width: 100%;
    height: 40px;
  }

  .forgotPasswordText {
    color: blue;
    text-align: center;
  }

  .disabled {
    pointer-events: none;
    cursor: disabled;
    color: lightGrey1;
  }

  .errorMessage {
    color: red;
    margin: calc(sm - md) 0 0;
  }

  .passwordVisibilityIcon {
    cursor: pointer;
  }

  .link {
    text-align: center;
    line-height: 1;
    color: blue;
    cursor: pointer;
    margin: 0 auto;

    span {
      font-weight: normal;
      font-size: 14px;
      font-family: var(--font-GtAmericaExtended);
    }

    &:hover {
      color: blue;
    }
  }
}
