/* stylelint-disable-next-line depop/validate-css-imports */
@value loginWithPassword from "../LoginForm/styles.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .loginWithMagicLink {
    composes: loginWithPassword;
    margin-bottom: sm;
  }
}
