@value red, white, black, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightedBlackColor: hsl(0deg, 0%, 35%); /* the original mineShaft/black is hsl(0, 0%, 15%) and this is 20% lighter */

@layer components {
    .address {
        font-style: normal;
    }

    .container {
        width: 100%;
        align-items: center;
        border-bottom: 1px solid lightGrey2;
        display: flex;
        flex-direction: column;
        padding: md xl;
        text-align: center;
    }

    .wrapper {
    width: 100%;
  }

  .deliveryFailureLink {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: sm 0;

        svg {
            margin-right: sm;
        }
    }

    .icon {
        align-items: center;
        border: 2px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: var(--icon-size);
        width: var(--icon-size);
    }

    .manualShipped,
    .tracking {
        margin-top: sm;
    }

    .shipmentStatusDetails {
        margin: sm 0;

        p {
            margin-bottom: sm;
        }
    }

    .highlight {
      color: red;
    }

  .packageLink {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: sm 0 md 0;
  }

  .markAsShippedButton {
    padding: 10px 0;
    margin-bottom: md;
  }
}

@layer overrides {
  .downloadLabelButton {
    margin: md 0 0;
    width: 100%;
    height: auto;
    border: 2px solid black;
    border-radius: 2px;
    padding: sm 0;
  }

  .downloadLabelButton:hover {
    text-decoration: none;
  }

  .downloadLabelButton svg {
    color: inherit;
    margin-right: xs;
  }

  .downloadLabelButton--mixed-bundle {
    margin: 0 0 md;
  }

  .downloadLabelButton--label {
    background-color: black;

    &:hover {
      color: lightedBlackColor;
      background-color: lightedBlackColor;
      border-color:  lightedBlackColor;
    }
  }

  .downloadLabelButton--label p {
    color: white;
  }

  .downloadLabelButton--qr {
    margin-top: sm;
    background-color: white;

    &:hover {
      background-color: white;
      border-color: lightedBlackColor;
    }
  }

  .downloadLabelButton--qr:hover p {
   color: lightedBlackColor;
  }

  .downloadLabelButton--qr p {
    color: black;
  }

  .downloadLabelButton--qr svg {
    vertical-align: middle;
  }

  .copyAddress {
    margin: md auto;
    width: 100%;
    color: red;

    &:hover {
      background-color: inherit;
      text-decoration: none;
    }
  }

  .copyAddress p {
    color: red;
  }

  .copyAddress svg {
    margin-right: xs;
    color: inherit;
    vertical-align: middle;
  }
}
