@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, white, lightGrey1, lightBlue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value productCardSoldText from "@/modules/styling/zIndex.module.css";
@value xs, md, sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .previewImageWrapper {
    position: relative;
    display: inline-flex;

    img {
      max-width: 88px;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  .previewImageDelete {
    svg {
      color: black;
    }

    position: absolute;
    top: calc(-1 * md);
    right: calc(-1 * md);
  }

  .previewImageDeleteIconContainer {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uploadImageButton {
    cursor: pointer;
  }

  .formSection {
    margin-bottom: 40px;
  }

  .sectionTitle {
    margin-bottom: md;
  }

  .textLeading {
    font-size: 16px;
  }

  .footer {
    background-color: white;
    position: sticky;
    bottom: 0;
    margin-left: calc(-1 * md);
    margin-right: calc(-1 * md);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: md;
    border-top: 1px solid lightGrey1;
  }

  .translucentHoverOverlay {
    height: 100%;
    background: black;
    opacity: 0.3;
  }

  .productAttributesContainer {
    display: none;
    margin: sm xs 0;

    @media breakpointMdUp {
      display: flex;
      justify-content: space-between;
    }
  }

  .checkboxOverlay {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
  }

  .checkboxButton {
    position: absolute;
    right: md;
    bottom: md;
  }

  .productGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: md md;
  }

  .loadingSpinner {
    text-align: center;
    padding: xl 0;
  }

  .dropdownTrigger {
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid #acacac;
    gap: xs;
    height: 28px;
    padding: sm 0 xs 0;
    align-items: center;
    box-sizing: content-box;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .dropdownTriggerOpen {
    .dropdownTriggerChevron {
      transform: rotate(180deg);
    }
  }

  .dropdownTriggerActive {
    .dropdownTriggerChevron {
      transform: rotate(180deg);
    }
  }

  .dropdownContent {
    margin-top: xs;
    position: static;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid #acacac;
    background: white;
    overflow-y: auto;
    max-height: 340px;
  }

  .dropdownOption {
    display: flex;
    flex-direction: column;
    gap: xs;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: md 0;
    margin: 0 md;
    cursor: pointer;
    border-bottom: 1px solid lightGrey1;

    &:last-child {
      border-bottom: none;
    }
  }

  .optionLabel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .badge {
    display: inline-flex;
    margin: 0 sm;
    padding: 2px sm xs sm;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 9px;
    font-weight: 400;
    line-height: 14px;
    border-radius: 20px;
    background: lightBlue;
    vertical-align: middle;
  }
}
