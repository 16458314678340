@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .container {
    display: none;

    @media breakpointMdUp {
      margin-bottom: sm;
      display: block;
    }
  }

  .nonDiscountedPrice {
    p {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .discountedPrice {
    p:first-child {
      font-size: 1rem;
    }

    p:last-child {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .discountLabel {
    display: inline-block;
    color: white;
    background: red;
    padding: xs sm;
    border-radius: borderRadiusSmall;
    margin: sm 0 md;
  }

  .taxIncludedText {
    display: block;
    margin-top: xs;
  }
}
