@value red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    display: inline-block;
    width: var(--size, 18px);
    height: var(--size, 18px);
    vertical-align: middle;
    border: 0.125rem solid transparent;
    border-top-color: var(--color, red);
    border-left-color: var(--color, red);
    border-radius: 50%;
    animation: rotate360 500ms linear infinite;
  }
}
