@layer components {
  .styles_previewImageWrapper__xFJsw {
    position: relative;
    display: inline-flex;
  }

    .styles_previewImageWrapper__xFJsw img {
      max-width: 88px;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 3px;
    }
    .styles_previewImageDelete__LA8Ja svg {
      color: #262626;
    }
    .styles_previewImageDelete__LA8Ja{

    position: absolute;
    top: calc(-1 * 16px);
    right: calc(-1 * 16px);
  }

  .styles_previewImageDeleteIconContainer__BN4D4 {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .styles_uploadImageButton__DxLqu {
    cursor: pointer;
  }

  .styles_formSection__hDdHf {
    margin-bottom: 40px;
  }

  .styles_sectionTitle__7LLod {
    margin-bottom: 16px;
  }

  .styles_textLeading__1YMND {
    font-size: 1rem;
  }

  .styles_footer__zYlMH {
    background-color: #FFFFFF;
    position: sticky;
    bottom: 0;
    margin-left: calc(-1 * 16px);
    margin-right: calc(-1 * 16px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-top: 1px solid #F3F3F3;
  }

  .styles_translucentHoverOverlay__KRUbC {
    height: 100%;
    background: #262626;
    opacity: 0.3;
  }

  .styles_productAttributesContainer__gXvZJ {
    display: none;
    margin: 8px 4px 0;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_productAttributesContainer__gXvZJ {
      display: flex;
      justify-content: space-between
  }
    }

  .styles_checkboxOverlay__kUbPX {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
  }

  .styles_checkboxButton__UwmNl {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  .styles_productGrid__PmNkA {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
  }

  .styles_loadingSpinner__zclZI {
    text-align: center;
    padding: 32px 0;
  }

  .styles_dropdownTrigger__gtoqI {
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid #acacac;
    gap: 4px;
    height: 28px;
    padding: 8px 0 4px 0;
    align-items: center;
    box-sizing: content-box;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
    .styles_dropdownTriggerOpen__Q_ley .styles_dropdownTriggerChevron__eV0J8 {
      transform: rotate(180deg);
    }
    .styles_dropdownTriggerActive__ZFYFD .styles_dropdownTriggerChevron__eV0J8 {
      transform: rotate(180deg);
    }

  .styles_dropdownContent__VHGjs {
    margin-top: 4px;
    position: static;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid #acacac;
    background: #FFFFFF;
    overflow-y: auto;
    max-height: 340px;
  }

  .styles_dropdownOption__HJq47 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: 16px 0;
    margin: 0 16px;
    cursor: pointer;
    border-bottom: 1px solid #F3F3F3;
  }

    .styles_dropdownOption__HJq47:last-child {
      border-bottom: none;
    }

  .styles_optionLabel__xEgex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .styles_badge__6cSr9 {
    display: inline-flex;
    margin: 0 8px;
    padding: 2px 8px 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 0.5625rem;
    font-weight: 400;
    line-height: 0.875rem;
    border-radius: 20px;
    background: #C5E0FF;
    vertical-align: middle;
  }
}

@layer components {
  @keyframes styles_rotate360__EFsC3 {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .styles_spinner__SP8A0 {
    display: inline-block;
    width: var(--size, 18px);
    height: var(--size, 18px);
    vertical-align: middle;
    border: 0.125rem solid transparent;
    border-top-color: var(--color, #E20020);
    border-left-color: var(--color, #E20020);
    border-radius: 50%;
    animation: styles_rotate360__EFsC3 500ms linear infinite;
  }
}

@layer components {
  .styles_focusWrapper__zK3b8 {
    outline: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

@layer components {
  .styles_loginFormContainer__FPJLm {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: stretch;
  }

  .styles_loginWithPassword__cfGNY {
    margin-top: 8px;
    width: 100%;
    height: 40px;
  }

  .styles_forgotPasswordText__ohMOz {
    color: #2960AF;
    text-align: center;
  }

  .styles_disabled__smxmS {
    pointer-events: none;
    cursor: disabled;
    color: #F3F3F3;
  }

  .styles_errorMessage__2J91e {
    color: #E20020;
    margin: calc(8px - 16px) 0 0;
  }

  .styles_passwordVisibilityIcon__Rc89w {
    cursor: pointer;
  }

  .styles_link__dN503 {
    text-align: center;
    line-height: 1;
    color: #2960AF;
    cursor: pointer;
    margin: 0 auto;
  }

    .styles_link__dN503 span {
      font-weight: normal;
      font-size: 0.875rem;
      font-family: var(--font-GtAmericaExtended);
    }

    .styles_link__dN503:hover {
      color: #2960AF;
    }
}

/* stylelint-disable-next-line depop/validate-css-imports */

@layer components {
  .styles_loginWithMagicLink__SIF3F {
    margin-bottom: 8px;
  }
}

@layer overrides {
  .styles_title__kWcg1 {
    font-weight: normal;
    font-size: 1.125rem /* 18px */;
    margin-bottom: 8px;
  }
}

@layer components {
  .styles_container__xdYLx {
    display: none;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_container__xdYLx {
      margin-bottom: 8px;
      display: block
  }
    }
    .styles_nonDiscountedPrice__p8kdR p {
      font-size: 1.125rem;
      font-weight: bold;
    }
    .styles_discountedPrice___NN_Q p:first-child {
      font-size: 1rem;
    }

    .styles_discountedPrice___NN_Q p:last-child {
      font-size: 1.125rem;
      font-weight: bold;
    }

  .styles_discountLabel__LgUaV {
    display: inline-block;
    color: #FFFFFF;
    background: #E20020;
    padding: 4px 8px;
    border-radius: 2px;
    margin: 8px 0 16px;
  }

  .styles_taxIncludedText__DQF2k {
    display: block;
    margin-top: 4px;
  }
}

@layer components {
  .styles_helperText__9Eh__ {
    color: #43484D;
    font-size: 0.625rem;
    padding: 8px 0 0;
  }

  .styles_truncatedMenu__khvpw {
    max-height: 362px;
  }
}

/* the original mineShaft/black is hsl(0, 0%, 15%) and this is 20% lighter */

@layer components {
    .styles_address__X08rf {
        font-style: normal;
    }

    .styles_container__nKmoI {
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #D7D7D7;
        display: flex;
        flex-direction: column;
        padding: 16px 32px;
        text-align: center;
    }

    .styles_wrapper__jMOOW {
    width: 100%;
  }

  .styles_deliveryFailureLink__GPUGM {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 8px 0;
    }

        .styles_deliveryFailureLink__GPUGM svg {
            margin-right: 8px;
        }

    .styles_icon__VMscj {
        align-items: center;
        border: 2px solid #262626;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: var(--icon-size);
        width: var(--icon-size);
    }

    .styles_manualShipped___YCNE,
    .styles_tracking__nZtOC {
        margin-top: 8px;
    }

    .styles_shipmentStatusDetails__IAYmz {
        margin: 8px 0;
    }

        .styles_shipmentStatusDetails__IAYmz p {
            margin-bottom: 8px;
        }

    .styles_highlight__XLC6A {
      color: #E20020;
    }

  .styles_packageLink__Oip6E {
    color: #E20020;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0 16px 0;
  }

  .styles_markAsShippedButton__snnbt {
    padding: 10px 0;
    margin-bottom: 16px;
  }
}

@layer overrides {
  .styles_downloadLabelButton__i3Wza {
    margin: 16px 0 0;
    width: 100%;
    height: auto;
    border: 2px solid #262626;
    border-radius: 2px;
    padding: 8px 0;
  }

  .styles_downloadLabelButton__i3Wza:hover {
    text-decoration: none;
  }

  .styles_downloadLabelButton__i3Wza svg {
    color: inherit;
    margin-right: 4px;
  }

  .styles_downloadLabelButton--mixed-bundle__HS_tv {
    margin: 0 0 16px;
  }

  .styles_downloadLabelButton--label__3i_n0 {
    background-color: #262626;
  }

    .styles_downloadLabelButton--label__3i_n0:hover {
      color: hsl(0deg, 0%, 35%);
      background-color: hsl(0deg, 0%, 35%);
      border-color:  hsl(0deg, 0%, 35%);
    }

  .styles_downloadLabelButton--label__3i_n0 p {
    color: #FFFFFF;
  }

  .styles_downloadLabelButton--qr__xUGZ1 {
    margin-top: 8px;
    background-color: #FFFFFF;
  }

    .styles_downloadLabelButton--qr__xUGZ1:hover {
      background-color: #FFFFFF;
      border-color: hsl(0deg, 0%, 35%);
    }

  .styles_downloadLabelButton--qr__xUGZ1:hover p {
   color: hsl(0deg, 0%, 35%);
  }

  .styles_downloadLabelButton--qr__xUGZ1 p {
    color: #262626;
  }

  .styles_downloadLabelButton--qr__xUGZ1 svg {
    vertical-align: middle;
  }

  .styles_copyAddress__SoWL5 {
    margin: 16px auto;
    width: 100%;
    color: #E20020;
  }

    .styles_copyAddress__SoWL5:hover {
      background-color: inherit;
      text-decoration: none;
    }

  .styles_copyAddress__SoWL5 p {
    color: #E20020;
  }

  .styles_copyAddress__SoWL5 svg {
    margin-right: 4px;
    color: inherit;
    vertical-align: middle;
  }
}

@layer components {
    .styles_icon__Trywn {
        color: #262626;
    }

    .styles_iconContainer__8ryGQ {
        display: flex;
    }
}
