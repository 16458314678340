@value grey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .helperText {
    color: grey;
    font-size: 10px;
    padding: sm 0 0;
  }

  .truncatedMenu {
    max-height: 362px;
  }
}
